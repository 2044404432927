import './App.css';
// src/App.js
import React from 'react';
import AppRouter from './Router';  // Import the Router file

function App() {
  return (
    <div>
      <AppRouter />  {/* Use the router */}
    </div>
  );
}

export default App;
