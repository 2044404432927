  import React from 'react';
  import { Link } from 'react-router-dom';
  import Footer from './Footer';

  function Layout({ children }) {
    
    return (
      <div className="min-h-screen flex flex-col">
        {/* Header */}
        <header className="bg-gray-800 text-white p-4">
          <nav className="max-w-md mx-auto flex justify-between">
            <p className="text-xl font-bold">
              <Link to="https://ulferik.se">Ulf's Web</Link></p>
            <div className=''>
              <Link to="/" className="mr-4 hover:underline">Home</Link>
            {/*  <Link to="/article" className="mr-4 hover:underline">Articles</Link> */}
              <Link to="/about" className="hover:underline">About</Link>
            </div>
          </nav>
        </header>

        {/* Main Content */}
        <main className="flex-grow max-w-[1200px] mx-auto p-4">
          {children}
        </main>

        <Footer />
      </div>
    );
  }

  export default Layout;
