// src/Router.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import VardiutlandetPage from './pages/Vard-i-utlandet';
import CascaisPage from './pages/CascaisPage';
function AppRouter() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/vard" element={<VardiutlandetPage />} />
                <Route path="/casc" element={<CascaisPage />} />
            </Routes>
        </Router>
    );
}

export default AppRouter;
