import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import linksData from '../links.json'; // Import the local JSON file
import Layout from '../components/Layout';
function HomePage() {
    const [links, setLinks] = useState([]);

    // Load the links from the JSON file
    useEffect(() => {
        setLinks(linksData);
    }, []);
    return (
        <Layout>
            <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl p-5">
                <div className="md:flex pt-10">
                    <div className="md:flex-shrink-0">
                        <nav className="mb-4 border border-spacing-x-10 shadow-2xl pb-5">
                            <p className='pt-10 p-3'>Artiklar</p>
                            <ul className="space-y">
                                {links.map((link) => (
                                    <li key={link.id}>
                                        <Link to={link.url} className="text-blue-600 hover:underline p-3">
                                            {link.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                    </div>
                    <div className='pl-10'>
                        <h1 className='font-bold'>Välkommen till Ulf's web</h1>
                        <p className=''>
                            Det här är platsen där jag delar med mig av mina tankar, erfarenheter och passioner. Oavsett om du är
                            intresserad av politik, matlagning, hur datorn funkar eller hur det är att bo i Portugal, så hoppas jag att
                            du hittar något inspirerande här.
                        </p>
                        {/*
                    <h2 className="">Här på bloggen kommer du att hitta:</h2>
                    <ul className="list-disc list-inside">
                        <li>Inspirerande inlägg: t.ex. <a href="rec.html">Recept</a></li>
                        <li>Tips och tricks: dator</li>
                        <li>Reflektioner: personliga tankar om politik och insikter från ett långt liv</li>
                        <li>Ekonomi: aktier m.m</li>
                    </ul>
                    */}
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default HomePage;
